@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;


.why_wrapper {
    background-color: #FAF8FC;
}

.why {
    @include ctt.content;

    flex-wrap: wrap;
    padding: 80px 16px;

    @media(min-width: bp.$sm) {
        padding: 96px 32px;
    }

    @media(min-width: bp.$md) {
        padding: 96px 48px;
    }

    @media(min-width: bp.$lg) {
        padding: 96px 32px;
    }

    @media(min-width: bp.$xl) {
        padding: 96px 64px;
    }

}

.why_title {
    @include title.t6xl;

    @media(min-width: bp.$xl) {
        @include title.t7xl;
    }

    width: 100%;
    text-align: center;
}

.why_content {
    margin-top: 80px;
    display: flex;
    gap: 32px;
    flex-wrap: wrap;

    @media(min-width: bp.$lg) {
        flex-wrap: nowrap;
    }
}

.why_content_desc {
    width: 100%;
}

.why_content_desc_btn {
    @include bt.number_button;
}

.why_content_desc_title {
    @include title.t2xl;

    margin-top: 20px;
}

.why_content_desc_msg {
    @include title.tlg;

    color: color.$gray-700;
    margin-top: 8px;
}