$purple-050: #E2E8F0;
$purple-100: #E2E8F0;
$purple-200: #DACDE9;
$purple-400: #A382C7;
$purple-900: #4C1D95;

$purbple-050-v2: #FAF8FC;

$gray-200: #E5E5E5;
$gray-600: #525252;
$gray-700: #404040;
$gray-900: #171717;