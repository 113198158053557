@use 'src/styles/content.module.scss' as ctt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;

.info {
    @include ctt.content;
    flex-wrap: wrap;

    padding: 64px 16px 0 16px;
    background: linear-gradient(180deg, rgba(234, 227, 242, 0) 0%, color.$purbple-050-v2 100%);

    @media(min-width: bp.$sm) {
        padding: 64px 32px 0 32px;
    }

    @media(min-width: bp.$md) {
        padding: 64px 48px 0 48px;
    }

    @media(min-width: bp.$lg) {
        padding: 64px 32px 0 32px;
    }

    @media(min-width: bp.$xl) {
        padding: 64px 64px 0 64px;
    }
}

.info_content {
    width: 100%;
    max-width: 672px;

    @media(min-width: bp.$xl) {
        max-width: 762px;
    }

    p {
        @include title.t4xl;
        color: color.$gray-900;
        text-align: center;

        @media(min-width: bp.$xl) {
            @include title.t5xl;
        }

        &.info_content_point {
            margin: 16px 0 80px 0;
            color: #5D209D;

            @media(min-width: bp.$xl) {
                margin-bottom: 96px;
            }
        }

    }
}

.info_img_wrapper {
    width: 100%;
    min-height: 280px;
    background-image: url('/assets/img/info_mobile_pc_sm.png');
    background-repeat: no-repeat;
    background-size: cover;

    @media(max-width: bp.$xs) {
        background-size: contain;
        height: 77.7vw;
    }

    @media(min-width: bp.$sm) {
        min-height: 360px;
        height: 360px;
        background-image: url('/assets/img/info_mobile_pc.png');
    }

    @media(min-width: bp.$md) {
        background-image: url('/assets/img/info_mobile_pc.png');
    }

    @media(min-width: bp.$lg) {
        height: 400px;
        min-height: 400px;
        background-image: url('/assets/img/info_mobiles_pc.png');
    }
}