@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;

.hero {
    @include ctt.content;

    padding: 80px 16px 0 16px;
    gap: 32px;
    flex-wrap: wrap;

    @media(min-width: bp.$sm) {
        padding: 80px 32px 0 32px;
    }

    @media(min-width: bp.$md) {
        padding: 80px 48px 0 48px;
    }

    @media(min-width: bp.$lg) {
        flex-wrap: nowrap;
        padding: 8px 32px 32px 32px;
    }

    @media(min-width: bp.$xl) {
        padding: 8px 64px 32px 64px;
    }
}

.hero_desc {
    width: 100%;

    @media(min-width: bp.$lg) {
        max-width: 456px;
    }

    @media(min-width: bp.$xl) {
        max-width: 552px;
    }
}

.hero_desc_title {
    @include title.t8xl;

    color: color.$gray-900;

    span {
        color: color.$purple-900;
    }
}

.hero_desc_detail {
    @include title.tlg;

    color: color.$gray-600;
    margin-top: 20px;
}

.hero_desc_buttons {
    display: flex;
    gap: 16px;
    margin-top: 32px;
}

.hero_desc_secondary_btn {
    @include bt.secondary_button
}

.hero_desc_primary_btn {
    @include bt.primary_button
}

.hero_img_wrapper {
    width: 100%;
    max-width: 576px;

    @media(min-width: bp.$lg) {
        max-width: 568px;
    }

    img {
        width: 100%;
    }
}