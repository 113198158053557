@use './color.module.scss' as color;
@use './font.module.scss' as font;


@mixin button {
    @include font.content-font;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 12px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    cursor: pointer;
}

@mixin primary_button {
    @include button;
    color: #fff;
    border:1px solid #330476;
    background-color: #4E0A94;
    font-weight: 450;
}

@mixin secondary_button {
    @include button;
    color: color.$gray-900;
    border: 1px solid color.$gray-200;
    background-color: #fff;
    font-weight: 450;
}

@mixin number_button {
    @include font.content-font;

    background: linear-gradient(135deg, rgba(218, 205, 233, 0) 0%, rgba(218, 205, 233, 0.5) 100%), #F0EBF6;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 36px;
    height: 36px;
    border: 1px solid #F0EBF6;
    color: #4E0A94;
    font-weight: 450;
    font-size: 18px;
    line-height: 26px;
}