@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/content.module.scss' as ctt;


.topbar {
    @include ctt.content;
    padding: 20px 16px;

    @media(min-width: bp.$sm) {
        padding: 20px 32px;
    }

    @media(min-width: bp.$md) {
        padding: 20px 48px;
    }

    @media(min-width: bp.$lg) {
        padding: 20px 32px;
    }

    @media(min-width: bp.$xl) {
        padding: 20px 64px;
        margin: 0 auto;
    }
}

.topbar_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.topbar_content_buttons {
    display: flex;
    flex-direction: row;
}

.tobpar_links {
    display: none;

    a {
        color: color.$gray-600;
        padding: 8px 0;
    }

    @media(min-width: bp.$md) {
        display: flex;
        gap: 16px;
        margin-right: 24px;
    }

    @media(min-width: bp.$lg) {
        gap: 20px;
        margin-right: 32px;
    }
}

.tobpar_button {
    @include bt.primary_button;
}