@use 'src/styles/title.module.scss' as title;
@use 'src/styles/color.module.scss' as color;
@use 'src/styles/button.module.scss' as bt;
@use 'src/styles/breakpoint.module.scss' as bp;
@use 'src/styles/content.module.scss' as ctt;

.testimonial {
    @include ctt.content;

    flex-wrap: wrap;

    padding: 80px 16px;
    gap: 0;

    @media(min-width: bp.$sm) {
        padding: 96px 32px;
    }

    @media(min-width: bp.$md) {
        padding: 96px 48px;

    }

    @media(min-width: bp.$lg) {
        padding: 96px 32px;
        gap: 32px;
    }

    @media(min-width: bp.$xl) {
        padding: 96px 64px;
        gap: 48px;

    }
}

.testimonial_title {
    @include title.t7xl;

    width: 100%;
    margin-bottom: 30px;

    @media(min-width: bp.$lg) {
        text-align: center;
    }
}

.testimonial_content {
    margin-top: 50px;
    width: 100%;

    @media(min-width: bp.$lg) {
        margin-top: 0;
        width: calc(50% - 24px);
    }

}

.testimonial_content_desc {
    @include title.tlg;
    color: color.$gray-900;
}

.testimonial_content_user {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 20px;
}

.testimonial_content_user_imgIcon {
    width: 48px;
    height: 48px;
}

.testimonial_content_user_desc {
    width: calc(100% - 60px);
}

.testimonial_content_user_name {
    @include title.txl;
    color: color.$gray-900;
}

.testimonial_content_user_role {
    color: color.$gray-600;
    font-size: 16px;
    line-height: 24px;
}